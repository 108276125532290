import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './PasswordInput.module.scss';
import eyeShowLight from '../../assets/icons/themes/light/eye-show.svg';
import eyeOffLight from '../../assets/icons/themes/light/eye-off.svg';
import eyeShowDark from '../../assets/icons/themes/dark/eye-show.svg';
import eyeOffDark from '../../assets/icons/themes/dark/eye-off.svg';

import useTheme from '../../hook/useTheme';
import { themesEnum } from '../../hoc/ThemeProvider';

/**
 * Password input
 * @component
 */
function PasswordInput({
  value,
  onChange,
  onKeyDown,
  id,
  title,
  innerRef,
}) {
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const { theme } = useTheme();

  // Switch or set specified isPasswordShow flag
  const switchPasswordShow = (state) => {
    if (state === true || state === false) {
      // Set the state if specified
      setIsPasswordShow(state);
    } else {
      // Switch it otherwise
      setIsPasswordShow(!isPasswordShow);
    }
  };

  const handleKeyDown = (event) => {
    if (event.type === 'keydown') {
      if (event.key === 'Enter' || event.key === 'Space') {
        switchPasswordShow();
      }
    }
  };

  const eyeOffImg = useMemo(() => {
    switch (theme) {
      case themesEnum.DARK:
        return eyeOffDark;
      case themesEnum.LIGHT:
        return eyeOffLight;
      default:
        return null;
    }
  }, [theme]);

  const eyeShowImg = useMemo(() => {
    switch (theme) {
      case themesEnum.DARK:
        return eyeShowDark;
      case themesEnum.LIGHT:
        return eyeShowLight;
      default:
        return null;
    }
  }, [theme]);

  return (
    <div className={styles.passwordInputWrapper}>
      <input
        className={styles.inputPassword}
        id={id}
        value={value}
        title={title}
        type={isPasswordShow ? 'text' : 'password'}
        onChange={onChange}
        onKeyDown={onKeyDown}
        ref={innerRef}
      />
      <div
        className={styles.showPassword}
        onClick={switchPasswordShow}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        <img
          src={isPasswordShow ? eyeOffImg : eyeShowImg}
          width="100%"
          height="100%"
          alt="Показать"
          title="Показать"
          draggable={false}
        />
      </div>
    </div>
  );
}

PasswordInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  id: PropTypes.string,
  title: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

PasswordInput.defaultProps = {
  id: '',
  title: '',
  innerRef: null,
};

export default PasswordInput;
