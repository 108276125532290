import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from './ChangePasswordForm.module.scss';

import { isResponse2xx } from '../../../utils/responses';
import useAuth from '../../../hook/useAuth';

import Button, { ButtonTypeEnum } from '../../Button/Button';
import InputWrapper, { InputStyles } from '../../InputWrapper/InputWrapper';
import PasswordInput from '../../PasswordInput/PasswordInput';
import { buildRoute as buildProfileRoute } from '../../../containers/ProfilePage/ProfilePage.Route';

/**
 * Change password form
 * @component
 */
function ChangePasswordForm({ showHeading, isFocused }) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [response, setResponse] = useState(null);

  const { changePassword } = useAuth();
  const inputRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    inputRef?.current?.focus();
  }, [isFocused]);

  const handleSubmit = async () => {
    changePassword(currentPassword, newPassword).then((resp) => {
      if (!resp) {
        // General error
        setErrorMessage('Не удалось изменить пароль');
        setResponse(null);
      } else if (isResponse2xx(resp.status)) {
        setErrorMessage('');
        setResponse(null);
        navigate(buildProfileRoute());
      } else {
        setErrorMessage('');
        setResponse(resp);
      }
    });
    // .catch((error) => setResponse({ status: 'error', data: error.response.data }));
  };

  const handleEnterSubmit = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };
  const handleCancel = (event) => {
    if (event?.type === 'keydown' && event?.key !== 'Enter') {
      return;
    }
    navigate(buildProfileRoute());
  };

  const getError = (selector) => {
    if (response?.status === 400) {
      const err = response?.errors?.find(
        (error) => error?.location === selector
      );
      return err ? err.message : '';
    }
    return '';
  };

  return (
    <div className={styles.wrapper}>
      {showHeading && (
        <div className={styles.heading}>
          <h3>Изменить пароль</h3>
        </div>
      )}
      <InputWrapper
        className={InputStyles.LINED}
        label="Ваш пароль"
        labelFor="changepwd-current"
        errorText={getError('body.currentPassword')}
      >
        <PasswordInput
          id="changepwd-current"
          value={currentPassword}
          title="Ваш пароль"
          onChange={(e) => setCurrentPassword(e.target.value)}
          onKeyDown={handleEnterSubmit}
          innerRef={inputRef}
        />
      </InputWrapper>

      <InputWrapper
        className={InputStyles.LINED}
        label="Новый пароль"
        labelFor="changepwd-new"
        errorText={getError('body.newPassword')}
      >
        <PasswordInput
          id="changepwd-new"
          value={newPassword}
          title="Новый пароль"
          onChange={(e) => setNewPassword(e.target.value)}
          onKeyDown={handleEnterSubmit}
        />
      </InputWrapper>

      {errorMessage && <p className={styles.errorText}>{errorMessage}</p>}

      <div className={styles.buttonsHolder}>
        <Button
          className={styles.button}
          type={ButtonTypeEnum.default}
          onClick={handleSubmit}
        >
          Сохранить
        </Button>
        <Button
          className={styles.button}
          type={ButtonTypeEnum.default}
          onClick={handleCancel}
        >
          Отменить
        </Button>
      </div>
    </div>
  );
}

ChangePasswordForm.propTypes = {
  showHeading: PropTypes.bool,
  // Flag if first input should be focused on init
  isFocused: PropTypes.bool,
};

ChangePasswordForm.defaultProps = {
  showHeading: true,
  isFocused: false,
};

export default ChangePasswordForm;
