import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import styles from './VideoPage.module.scss';

import useAuth from '../../hook/useAuth';
import useError from '../../hook/useError';
import useCourses from '../../hook/useCourses';
import { joinClasses } from '../../utils/helper';
import { VideoControlsProvider } from '../../hoc/VideoControlsProvider';

import TimingList from '../../components/TimingList/TimingList';
import VideoList from '../../components/VideoList/VideoList';
import Video from '../../components/Video/Video';
import RenderHTML from '../../components/RenderHTML/RenderHTML';

import { buildRoute as buildCourseRoute } from '../CoursePage/CoursePage.Route';
import useWindowSize from '../../hook/useWindowSize';
import { desktopScreenWidth } from '../../utils/consts';

const VISIBLE_TEXT_LIMIT = 256;

/**
 * Video page
 * @component
 */
function VideoPage() {
  const [isExpandedDesc, setIsExpandedDesc] = useState(false);
  const { width } = useWindowSize();

  const params = useParams();

  const { user } = useAuth();
  const { raiseError } = useError();

  const {
    course,
    video,
    videos,
    isLoadingVideos,
    loadCourseById,
    loadVideoById,
    isSingleVideoLoading,
  } = useCourses();

  const navigate = useNavigate();

  // Load the video on change of video ID
  useEffect(() => {
    setIsExpandedDesc(false);
    console.log('videoId changed', params.videoId);
    if (user) {
      loadVideoById(params.videoId, params.courseId);
      window.scrollTo(0, 0);
    }
  }, [user, params.videoId]);

  // Load course and relatives (videos) on change of Course ID
  useEffect(() => {
    if (user) {
      loadCourseById(params.courseId);
    }
  }, [user, params.courseId]);

  useEffect(() => {
    if (course && !course.isPurchased) {
      navigate(buildCourseRoute(course));
    }
  }, [course]);

  useEffect(() => {
    if (typeof course === 'undefined') {
      raiseError(404, 'Not found');
    }
    if (typeof video === 'undefined') {
      raiseError(404, 'Not found');
    }
  }, [course, video]);

  const getContentClass = () => {
    let cls = styles.content;
    if (!isExpandedDesc && video?.description?.length > VISIBLE_TEXT_LIMIT) {
      cls += ` ${styles.clickable}`;
    }
    return cls;
  };

  const expandDescription = () => {
    if (!isExpandedDesc && video.description.length > VISIBLE_TEXT_LIMIT) {
      setIsExpandedDesc(true);
    }
  };

  const handleSelectEpisode = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={styles.holder}>
      {/* eslint-disable-next-line no-nested-ternary */}
      <VideoControlsProvider>
        <div className={styles.videoWrapper}>
          <Video
            video={video}
            isLoading={isLoadingVideos || isSingleVideoLoading}
          />
        </div>

        {video?.timings?.length && (
          <div className={styles.heightFixBlock}>
            <h3>Эпизоды</h3>
            <div className={styles.scrollHolder}>
              <div className={styles.episodeListBlock}>
                {video?.timings?.length && (
                  <TimingList
                    timings={video.timings ? video.timings : []}
                    onSelect={handleSelectEpisode}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <div className={styles.description}>
          <h1 className={styles.title}>{video?.title || <Skeleton />}</h1>

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
          jsx-a11y/no-static-element-interactions */}
          <div className={getContentClass()} onClick={expandDescription}>
            {((isExpandedDesc || width >= desktopScreenWidth) &&
              video?.description && (
                <RenderHTML htmlString={video?.description} />
              )) ||
              (video?.description?.length && (
                <>
                  <RenderHTML
                    htmlString={video?.description}
                    short={video?.description?.length > VISIBLE_TEXT_LIMIT}
                  />
                  {video?.description?.length > VISIBLE_TEXT_LIMIT &&
                    width < desktopScreenWidth && (
                      // eslint-disable-next-line react/jsx-one-expression-per-line
                      <p className={styles.showMore}>
                        ...&thinsp;
                        <span>показать полностью</span>
                      </p>
                    )}
                </>
              )) || <Skeleton count={3} />}
          </div>
        </div>

        <div
          className={joinClasses([
            styles.videoListHolder,
            !video || video?.timings?.length ? '' : styles.noEpisodes,
            !video?.timings?.length && styles.extendedVideos,
          ])}
        >
          <div className={styles.videoListBlock}>
            <h3>Видео этого курса</h3>
            <VideoList className={styles.videoList} />
          </div>
        </div>
      </VideoControlsProvider>
    </div>
  );
}

VideoPage.propTypes = {};

export default VideoPage;
