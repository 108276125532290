import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';
import styles from './ProfileCard.module.scss';
import defaultImgLight from '../../assets/images/themes/light/user_photo.svg';
import defaultImgDark from '../../assets/images/themes/dark/user_photo.svg';

import useAuth from '../../hook/useAuth';
import useTheme from '../../hook/useTheme';
import { joinClasses } from '../../utils/helper';
import { themesEnum } from '../../hoc/ThemeProvider';
import useCourses from '../../hook/useCourses';

import { getRoute as getMyCoursesRoute } from '../../containers/MyCoursesPage/MyCoursesPage.Route';

/**
 * User profile card
 * @component
 */
function ProfileCard({ className }) {
  const { user } = useAuth();
  const { theme } = useTheme();
  const { resetState } = useCourses();
  const { pathname } = useLocation();

  const isMyCourses = pathname === getMyCoursesRoute();

  const userImg = useMemo(() => {
    switch (theme) {
      case themesEnum.DARK:
        return defaultImgDark;
      case themesEnum.LIGHT:
      default:
        return defaultImgLight;
    }
  }, [theme]);

  const handleReset = (location) => {
    if (location !== pathname) {
      resetState();
    }
  };

  return (
    <div className={joinClasses([styles.card, className])}>
      <div className={styles.personal}>
        <div className={styles.photo}>
          {(user && (
            <div className={styles.circle}>
              <img src={userImg} alt="Фотография студента" />
            </div>
          )) || (
            <Skeleton
              className={styles.circle}
              style={{ width: '56px', height: '56px' }}
            />
          )}
        </div>
        <div className={styles.info}>
          {(user && (
            <div className={styles.name}>
              {`${user.given_name} ${user?.family_name}`}
            </div>
          )) || <Skeleton className={styles.name} />}
          {(user && (
            <>
              {user?.phone_number && (
                <div className={styles.phone}>
                  <span className={styles.plus}>+</span>
                  {user?.phone_number}
                </div>
              )}
              {user?.email && <div className={styles.email}>{user?.email}</div>}
            </>
          )) || <Skeleton className={styles.phone} />}
        </div>
      </div>
      {/* <div className={styles.stats}>
        <div className={styles.duration}>
          {(user && (
            <>
              <div className="value">14</div>
              <div className="desc">дней обучения</div>
            </>
          )) || (
            <>
              <Skeleton />
              <Skeleton />
            </>
          )}
        </div>
        <div className={styles.completed}>
          {(user && (
            <>
              <div className="value">8/24</div>
              <div className="desc">курсов пройдено</div>
            </>
          )) || (
            <>
              <Skeleton />
              <Skeleton />
            </>
          )}
        </div>
      </div> */}

      {(user && (
        <Link
          className={styles.link}
          onClick={() => handleReset(getMyCoursesRoute())}
          to={getMyCoursesRoute()}
        >
          {isMyCourses ? 'Мои курсы' : 'Перейти в мои курсы'}
        </Link>
      )) || <Skeleton className={styles.link} />}
    </div>
  );
}

ProfileCard.propTypes = {
  className: PropTypes.string,
};

ProfileCard.defaultProps = {
  className: '',
};

export default ProfileCard;
