import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';

import styles from './Video.module.scss';
import imageStubLight from '../../assets/images/themes/light/course_stub_1920x1080.png';
import imageStubDark from '../../assets/images/themes/dark/course_stub_1920x1080.png';

import { checkIfVideoExpirationIsValid, joinClasses } from '../../utils/helper';
import { ImageParamsEnum, ImageSizeEnum } from '../../utils/settings';
import { VideoShape } from '../../apis/models/video';
import useVideoControls from '../../hook/useVideoControls';
import useTheme from '../../hook/useTheme';

import Controls from './Controls/Controls';
import { themesEnum } from '../../hoc/ThemeProvider';

// Mouse inactivity time over video to hide controls
const HIDE_OUT_TIME = 2000;
// Throttling time to reduce the frequency of setTimeout
const THROTTLE_TIME = 500;

// Timeout for throttle function
let throttleTimeout;
// Timeout for hide controls
let hideTimeout;

function Video({ className, video, isLoading }) {
  const [isShowCover, setIsShowCover] = useState(true);
  const [isControlHidden, setIsControlHidden] = useState(false);
  const [isVideoDisabled, setIsVideoDisabled] = useState(false);
  const [disabledVideoText, setDisabledVideoText] = useState('');

  const playerRef = useRef();
  const playerContainerRef = useRef();

  const { theme } = useTheme();

  const {
    isPlaying, setIsPlaying,
    targetCounter,
    speed,
    level,
    volume,
    isMuted,
    isFullscreen,
    targetSec,
    setDurationSec,
    setLoadedSec,
    setPlayedSec,
    setLevelsList,
    // setIsFullscreen,
  } = useVideoControls();
  // console.log('video url:', video.link);

  // Scroll to position
  useEffect(() => {
    if (playerRef?.current && Number.isInteger(targetSec)) {
      playerRef.current.seekTo(Number(targetSec));
      setIsControlHidden(false);
      // eslint-disable-next-line no-use-before-define
      resetControlHideOut();
    }
  }, [targetSec, targetCounter]);

  // Change quality level of the video
  useEffect(() => {
    const hlsPlayer = playerRef?.current?.getInternalPlayer('hls');
    if (hlsPlayer) {
      // Select next fragment level for smooth playback
      hlsPlayer.nextLevel = level; // Trigger a quality level switch for next fragment
      const levelHeight = hlsPlayer?.levels[level]?.height;
      console.log(`Video level changed to: ${levelHeight ? `${levelHeight}p ` : ''}(${level})`);
    }
  }, [level]);

  // Process screenfull events
  // useEffect(() => {
  //   screenfull.on('change', () => {
  //     setIsFullscreen(screenfull.isFullscreen);
  //   });
  //   return () => {
  //     screenfull.off('change');
  //   };
  // }, []);

  // Toggle fullscreen
  useEffect(() => {
    if (screenfull.isEnabled) {
      if (isFullscreen && !screenfull.isFullscreen) {
        // console.log('enter Fullscreen');
        screenfull.request(playerContainerRef.current);
      } else {
        // console.log('exit Fullscreen');
        screenfull.exit();
      }
      // console.log('skip Fullscreen');
    }
  }, [isFullscreen]);

  // handle changed video
  useEffect(() => {
    if (video !== null) {
      const { isValid, errorText } = checkIfVideoExpirationIsValid(video.startTime, video.expirationTime);
      setIsVideoDisabled(!isValid);
      setDisabledVideoText(errorText);
    }
    setIsPlaying(false);
    setIsShowCover(true);
    setLoadedSec(0);
    setPlayedSec(0);
    setLevelsList([]);
    if (video && video?.duration) {
      setDurationSec(video?.duration);
    } else {
      setDurationSec(0);
    }
  }, [video]);

  const resetControlHideOut = () => {
    clearTimeout(hideTimeout);
    hideTimeout = setTimeout(() => {
      setIsControlHidden(true);
    }, HIDE_OUT_TIME);
  };

  const handleMouseMove = () => {
    if (!throttleTimeout) {
      setIsControlHidden(false);
      throttleTimeout = setTimeout(() => {
        resetControlHideOut();
        throttleTimeout = undefined;
      }, THROTTLE_TIME);
    }
  };

  const handleOnPlay = () => {
    setIsShowCover(false);
    setIsPlaying(true);
    setIsControlHidden(false);
    resetControlHideOut();
  };

  const handleOnPause = () => {
    setIsPlaying(false);
  };

  const handleProgress = (e) => {
    setLoadedSec(e.loadedSeconds);
    setPlayedSec(e.playedSeconds);
  };

  const handleDuration = (value) => {
    setDurationSec(value);
  };

  const handlePlayerLoad = () => {
    const hlsPlayer = playerRef.current.getInternalPlayer('hls');
    if (hlsPlayer && hlsPlayer.levels) {
      // console.log('%cVideo player loaded...', 'color: green;');
      // console.log('Levels: ', hlsPlayer.levels);
      const levelsListUpd = [];
      hlsPlayer.levels.forEach((levelData) => {
        levelsListUpd.push(`${levelData.height}p`);
      });
      setLevelsList(levelsListUpd);
    }
  };

  // Prepare video cover
  const coverImg = useMemo(() => {
    if (video) {
      const imgObj = video?.image?.instances?.[ImageSizeEnum.full];
      return imgObj || null;
    }

    let imageStub = imageStubLight;
    switch (theme) {
      case themesEnum.DARK:
        imageStub = imageStubDark;
        break;
      case themesEnum.LIGHT:
      default:
        imageStub = imageStubLight;
        break;
    }

    return {
      url: imageStub,
      width: ImageParamsEnum[ImageSizeEnum.full].width,
      height: ImageParamsEnum[ImageSizeEnum.full].height,
    };
  }, [video, theme]);

  return (
    <div
      ref={playerContainerRef}
      className={joinClasses([styles.videoContainer, className])}
      onMouseMove={handleMouseMove}
    >
      {isVideoDisabled && (
      <div className={styles.disablingVideoContainer}>
        <div className={styles.disablingVideoText}>
          {disabledVideoText}
        </div>
      </div>
      )}
      <div className={styles.videoWrapper}>
        <div className={styles.spacer} />
        <div className={styles.videoHolder}>
          {isShowCover && coverImg && (
            <div className={styles.videoCover}>
              <img
                src={coverImg.url}
                width={coverImg.width}
                height={coverImg.height}
                alt={video?.title || 'Видео загружается'}
              />
            </div>
          )}

          {isLoading && (
            <div className={styles.loaderHolder}>
              <div className={styles.loader} />
            </div>
          )}

          {video && (
            <ReactPlayer
              url={video.link}
              controls={false}
              width="100%"
              height="100%"
              playing={isPlaying}
              volume={isMuted ? 0 : volume}
              ref={playerRef}
              onReady={handlePlayerLoad}
              onProgress={handleProgress}
              onPlay={handleOnPlay}
              onPause={handleOnPause}
              onDuration={handleDuration}
              playbackRate={speed}
            />
          )}
        </div>
        <div className={styles.spacer} />
      </div>
      <Controls
        className={joinClasses([
          styles.controls,
          isPlaying && isControlHidden ? styles.hidden : '',
        ])}
      />
    </div>
  );
}

Video.propTypes = {
  className: PropTypes.string,
  video: VideoShape,
  isLoading: PropTypes.bool.isRequired,
};

Video.defaultProps = {
  className: '',
  video: null,
};

export default Video;
