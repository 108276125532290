/* eslint-disable object-property-newline */
import React, {
  createContext, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';

export const VideoControlsContext = createContext(null);

export const AUTO_LEVEL_VALUE = -1;

export function VideoControlsProvider({ children }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [durationSec, setDurationSec] = useState(0);
  const [loadedSec, setLoadedSec] = useState(0);
  const [playedSec, setPlayedSec] = useState(0);
  const [targetSec, setTargetSec] = useState(0);
  const [targetCounter, setTargetCounter] = useState(0);
  const [volume, setVolume] = useState(0.5);
  const [isMuted, setIsMuted] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [level, setLevel] = useState(AUTO_LEVEL_VALUE);
  const [levelsList, setLevelsList] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const setDurationSecInternal = (value) => setDurationSec(Math.round(value));

  const setLoadedSecInternal = (value) => setLoadedSec(Math.round(value));

  const setPlayedSecInternal = (value) => setPlayedSec(Math.round(value));
  /**
   * Wrap target play time
   * Improve interface responsiveness with instant set of playedSec
  */
  const setTargetSecInternal = (value) => {
    const fixedValue = Math.max(Math.min(Math.round(value), durationSec), 0);
    setTargetSec(fixedValue);
    setPlayedSec(fixedValue);
    setTargetCounter(targetCounter + 1);
  };

  const value = useMemo(() => ({
    isPlaying, setIsPlaying,
    durationSec, setDurationSec: setDurationSecInternal,
    loadedSec, setLoadedSec: setLoadedSecInternal,
    playedSec, setPlayedSec: setPlayedSecInternal,
    targetSec, setTargetSec: setTargetSecInternal,
    targetCounter,
    volume, setVolume,
    isMuted, setIsMuted,
    speed, setSpeed,
    level, setLevel,
    levelsList, setLevelsList,
    isFullscreen, setIsFullscreen,
  }), [
    isPlaying,
    durationSec,
    loadedSec,
    playedSec,
    targetSec,
    volume,
    isMuted,
    speed,
    level,
    levelsList,
    isFullscreen,
  ]);

  return (
    <VideoControlsContext.Provider value={value}>
      {children}
    </VideoControlsContext.Provider>
  );
}

VideoControlsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
