import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './VideoListCard.module.scss';
// TODO: video thumb image should be replaced by production version
import coverDummyImg from '../../../assets/images/video_thumb_stub.png';

import { CourseShape } from '../../../apis/models/course';
import { VideoShape } from '../../../apis/models/video';
import { prepareTimeFromSeconds } from '../../../utils/strings';
import { ImageParamsEnum, ImageSizeEnum } from '../../../utils/settings';
import { joinClasses } from '../../../utils/helper';

import { buildRoute as buildVideoRoute } from '../../../containers/VideoPage/VideoPage.Route';

function VideoListCard({ course, video }) {
  const getRoundMin = (seconds) => {
    const min = Math.round(seconds / 60);
    return min || 1;
  };

  // Prepare video thumb image
  const coverImg = useMemo(() => {
    const imgObj = video?.image?.instances?.[ImageSizeEnum.thumb];
    return imgObj || {
      url: coverDummyImg,
      width: ImageParamsEnum[ImageSizeEnum.thumb].width,
      height: ImageParamsEnum[ImageSizeEnum.thumb].height,
      alt: 'Изображение загружается',
    };
  }, [video]);

  const getBody = () => (
    <>
      <div className={styles.videoText}>
        <h4 className={styles.videoHeading}>{video.title}</h4>
        {/* <p className={styles.videoDescription}>{video.description}</p> */}
      </div>
      <div className={styles.videoCover}>
        <img
          src={coverImg.url}
          width={coverImg.width}
          height={coverImg.height}
          alt={video.title}
        />
      </div>
      <div className={styles.videoDuration}>
        {/* <img src={clock} width="12px" height="12px" alt="Продолжительность видео" /> */}
        { /* eslint-disable-next-line react/jsx-one-expression-per-line */}
        <span className={styles.approxDuration}>{getRoundMin(video.duration)}&thinsp;мин</span>
        <span className={styles.exactDuration}>{prepareTimeFromSeconds(video.duration)}</span>
      </div>
    </>
  );

  if (course.isPurchased) {
    return (
      <NavLink
        className={({ isActive }) => joinClasses([styles.videoCard, isActive ? styles.activeCard : ''])}
        to={buildVideoRoute(course, video)}
      >
        {getBody()}
      </NavLink>
    );
  }
  return (
    <div className={joinClasses([styles.videoCard, styles.disabled])}>
      {getBody()}
    </div>
  );
}

VideoListCard.propTypes = {
  // Course object
  course: CourseShape.isRequired,
  // Video object
  video: VideoShape.isRequired,
};

VideoListCard.defaultProps = {};

export default VideoListCard;
