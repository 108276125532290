import PropTypes from 'prop-types';
import styles from '../StreamPage.module.scss';
import { joinClasses } from '../../../utils/helper';

export function PersonComponent({
  description, name, avatar, additionalInfo, hasLinks,
}) {
  return (
    <div className={styles.section}>
      <div className={styles.avatar}>
        <img className={styles.avatarImage} width={72} height={72} src={avatar} alt="Person" />

      </div>
      <span className={styles.name}>
        {name}
      </span>
      <span className={joinClasses([styles.description, additionalInfo.length ? styles.doubleDescription : ''])}>
        {
          hasLinks
            ? <a href={`tel:${description}`} className={styles.text}>{description}</a>
            : <span>
              {description}
            </span>
        }
        {
          hasLinks && !!additionalInfo.length && <a className={styles.text} href={`mailto:${additionalInfo}`}>{additionalInfo}</a>
}
        {!hasLinks && !!additionalInfo.length && <span>{additionalInfo}</span>}
      </span>
    </div>
  );
}

PersonComponent.propTypes = {
  description: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string,
  additionalInfo: PropTypes.string,
  hasLinks: PropTypes.bool,
};

PersonComponent.defaultProps = {
  description: '',
  avatar: '',
  name: '',
  additionalInfo: '',
  hasLinks: false,
};
